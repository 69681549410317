import * as React from 'react';

function EyeOpenIconComponent(props: any) {
  return (
    <svg
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33335 8C5.33335 9.47 6.53002 10.6667 8.00002 10.6667C9.47002 10.6667 10.6667 9.47 10.6667 8C10.6667 6.53 9.47002 5.33333 8.00002 5.33333C6.53002 5.33333 5.33335 6.53 5.33335 8ZM6.33335 8C6.33335 7.08 7.08002 6.33333 8.00002 6.33333C8.92002 6.33333 9.66668 7.08 9.66668 8C9.66668 8.92 8.92002 9.66667 8.00002 9.66667C7.08002 9.66667 6.33335 8.92 6.33335 8Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.37668 8.20667C1.46335 8.4 3.56002 13 8.00002 13C12.44 13 14.5367 8.40333 14.6233 8.20667L14.72 7.98667L14.61 7.77333C14.51 7.57667 12.09 3 8.00002 3C3.56002 3 1.46335 7.60333 1.37668 7.8L1.28668 8.00333L1.37668 8.20667ZM8.00002 12C4.70002 12 2.83002 8.84333 2.39002 8.00333C2.83002 7.16 4.70335 4 8.00002 4C11.0167 4 13.09 7.15333 13.6 8.01667C13.1467 8.88 11.3 12 8.00002 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EyeOpenIconComponent;
