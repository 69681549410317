import React from 'react';
import EyeOpenIconComponent from '@components/icons/eyeOpenIcon';
import EyeCloseIconComponent from '@components/icons/eyeClosedIcon';
import LockOutlineIconComponent from '@components/icons/lockOutlineIcon';
import { FormErrorText } from './Texts';

interface InputFieldComponentProps {
  onEnter: () => void;
  id?: string;
  placeholder?: string;
  name?: string;
  type?: 'password' | 'text' | 'email';
  required?: boolean;
  value?: string;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: 'on' | 'off';
  errorMessage?: string;
  disabled?: boolean;
}

function InputFieldComponent(
  {
    id,
    placeholder,
    name,
    type,
    required,
    value,
    defaultValue,
    onChange,
    autoComplete,
    errorMessage,
    disabled,
    onEnter,
  }: InputFieldComponentProps,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const [showPassword, setShowPassword] = React.useState(false);

  if (disabled) {
    return (
      <>
        <div className="flex w-full cursor-not-allowed appearance-none items-center rounded-xl border bg-marketing-form-surface">
          <input
            id={id}
            placeholder={placeholder}
            name={name}
            type={type}
            required={required}
            defaultValue={defaultValue}
            onChange={onChange}
            autoComplete={autoComplete}
            className="peer h-12 flex-1 cursor-not-allowed rounded-xl bg-marketing-form-surface px-4 py-[9px] text-text-disabled-light focus:outline-none"
            disabled
            ref={ref}
          ></input>
          <div className="px-3">
            <LockOutlineIconComponent className="h-6 w-6 text-icon-disabled-light" />
          </div>
        </div>
        <FormErrorText className="flex h-6 items-center text-xs text-marketing-form-error">
          {errorMessage}
        </FormErrorText>
      </>
    );
  }
  return (
    <>
      <div
        className={`flex w-full appearance-none rounded-xl border bg-marketing-form-surface text-marketing-form-text focus-within:border-marketing-button-primary-hover focus-within:ring-marketing-button-primary-hover hover:border-marketing-button-primary-hover ${
          errorMessage
            ? 'border-marketing-form-error text-marketing-form-error focus-within:text-marketing-form-error hover:border-marketing-form-error'
            : 'border-marketing-form-surface'
        } `}
      >
        <input
          id={id}
          placeholder={placeholder}
          name={name}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          required={required}
          value={value}
          onChange={onChange}
          autoComplete={autoComplete}
          className="peer h-12 flex-1 rounded-xl bg-marketing-form-surface px-4 py-[9px] placeholder:text-marketing-form-placeholder focus:outline-none"
          ref={ref}
          onKeyDown={(e) => {
            e.key === 'Enter' && onEnter && onEnter();
          }}
        ></input>
        {type === 'password' && (
          <button
            className={`px-4 text-marketing-form-placeholder focus:outline-none ${
              errorMessage
                ? ''
                : 'focus-within:text-marketing-form-text peer-focus:text-marketing-form-text'
            }`}
            type="button"
            onClick={() => {
              setShowPassword(!showPassword);
            }}
          >
            {showPassword ? (
              <EyeOpenIconComponent className="h-5 w-5 cursor-pointer" />
            ) : (
              <EyeCloseIconComponent className="h-5 w-5 cursor-pointer" />
            )}
          </button>
        )}
      </div>
      <FormErrorText className="flex h-6 items-center text-xs text-marketing-form-error">
        {errorMessage}
      </FormErrorText>
    </>
  );
}

export default React.forwardRef(InputFieldComponent);
