import * as React from 'react';

function EyeCloseIconComponent(props: any) {
  return (
    <svg
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0801 11.98L21.9351 12.31C21.8551 12.49 20.6551 15.125 18.2151 17.155L21.2801 20.22L20.2201 21.28L2.72005 3.77997L3.78005 2.71997L7.01505 5.95497C8.38005 5.09997 10.0351 4.49997 12.0001 4.49997C18.1351 4.49997 21.7651 11.37 21.9151 11.66L22.0801 11.98ZM10.8501 9.78997L14.2101 13.15C14.3901 12.805 14.5001 12.415 14.5001 12C14.5001 10.62 13.3801 9.49997 12.0001 9.49997C11.5801 9.49997 11.1951 9.60997 10.8501 9.78997ZM16.0001 12C16.0001 12.835 15.7401 13.605 15.3051 14.245L17.1251 16.065C18.9101 14.615 20.0051 12.765 20.3951 12.02C19.6301 10.725 16.5251 5.99497 11.9951 5.99497C10.5101 5.99497 9.22505 6.42997 8.12505 7.06497L9.75505 8.69497C10.3951 8.25997 11.1701 7.99997 12.0001 7.99997C14.2051 7.99997 16.0001 9.79497 16.0001 12Z"
        fill="currentColor"
      />
      <path
        d="M3.58505 12.005C4.24505 13.265 7.05505 18 12.0001 18C12.8701 18 13.6751 17.845 14.4151 17.595L15.5901 18.77C14.5301 19.22 13.3401 19.5 12.0001 19.5C5.34005 19.5 2.19505 12.605 2.06505 12.31L1.93005 12.005L2.06505 11.7C2.06634 11.6973 2.06788 11.6939 2.06969 11.69C2.16668 11.4813 3.0213 9.64201 4.69505 7.875L5.77005 8.95C4.61005 10.175 3.88005 11.44 3.58505 12.005Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default EyeCloseIconComponent;
