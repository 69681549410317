import { User } from 'next-firebase-auth';
import { fetcherAndLog } from '@utils/fetcher';
import { userToken } from '@utils/firebase/initAuth';
import { getBaseUrl } from '@utils/general-utils';

export async function postContactDetails(fullName: string, user: User) {
  const token = await userToken(user);
  const url = `${getBaseUrl(false)}/dev/contactDetails`;
  const body = {
    fullName: fullName,
  };
  const result = await fetcherAndLog(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(body),
  });

  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `${message ? message : 'Something went wrong updating your name.'}`,
    );
  }
  return result.json();
}

export async function sendDataToCustomerIO(user: User) {
  const token = await userToken(user);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const result = await fetch(
    'https://mimoauth.getmimo.com/api/sendCustomerIoData',
    {
      method: 'POST',
      body: JSON.stringify({ timezone }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
    },
  );

  if (!result.ok) {
    const { message } = await result.json();
    throw new Error(
      `${message ? message : 'Something went wrong updating your name.'}`,
    );
  }
}
