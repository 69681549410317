import { assign, createMachine, fromPromise } from 'xstate';
import { AuthError, UserCredential } from 'firebase/auth';
import {
  signInWithEmailAndPassword,
  signInWithGoogle,
  signInWithApple,
  signInWithFacebook,
  didLoginOrRegisterWeb,
} from '@utils/auth';
import { isEmail } from '@utils/typeGuards';

const trackPlatformVisit = fromPromise(
  async ({ input }: { input: { userCredentials?: UserCredential } }) => {
    const user = input.userCredentials?.user;
    if (!user) {
      return;
    }
  },
);

const didLoginOrRegister = fromPromise(
  async ({ input }: { input: { userCredentials?: UserCredential } }) => {
    if (input.userCredentials) {
      didLoginOrRegisterWeb(input.userCredentials);
    }
  },
);

const submitLogin = fromPromise(
  async ({
    input,
  }: {
    input: { event: LoginStateEvent; email: string; password: string };
  }) => {
    switch (input.event.type) {
      case 'SUBMIT_FORM': {
        const { email, password } = input;
        const response = await signInWithEmailAndPassword(email, password);

        return response;
      }
      case 'LOGIN_WITH_GOOGLE': {
        const response = signInWithGoogle();
        return response;
      }
      case 'LOGIN_WITH_APPLE': {
        const response = signInWithApple();
        return response;
      }
      case 'LOGIN_WITH_FACEBOOK': {
        const response = signInWithFacebook();
        return response;
      }
      default:
        break;
    }
  },
);

export type LoginComponentState = InitialLoginState;
export type EmailError = '' | 'Email is missing' | 'Invalid email address';
export type PasswordError =
  | ''
  | 'Password is missing'
  | 'Password must be at least 6 characters';

interface InitialLoginState {
  email: string;
  password: string;
  errors: {
    email: EmailError;
    password: PasswordError;
    serviceError: string;
  };
  data?: UserCredential;
}

interface LoginStateContext {
  state: LoginComponentState;
}

export type LoginStateEvent =
  | {
      type: 'ENTER_EMAIL';
      email?: string;
    }
  | {
      type: 'ENTER_PASSWORD';
      password?: string;
    }
  | {
      type: 'SUBMIT_FORM';
      data: {
        email?: string;
        password?: string;
      };
    }
  | {
      type: 'LOGIN_WITH_GOOGLE';
    }
  | {
      type: 'LOGIN_WITH_APPLE';
    }
  | {
      type: 'LOGIN_WITH_FACEBOOK';
    };

const loginStateMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QBsD2UCWA7AygFwEM8wA6bDPAYgFEA5AFWoCUB9agWQEEBJAGQG0ADAF1EoAA6pYFDKixiQAD0QA2AIwkVgtQFYALPoDsADhUBmAEx6zAGhABPRIYCcOkoJ1ntx43r2G1Qz0AX2C7NExcQmIyLAoaBmYWAAVOHBwAdQB5JgARIVEkEElpPFl5IuUEPQs7RwQVZ2d3JucPFWtnY09Q8PRsfCJSciocAFUAIXZuehYAMRz2AoUSmTkFKrM1DT1BFQsLNUbBLxUVQzrVNT0SQ-9AswCgy16QCIHo4bjRyenZhaYSzUhQkUjWFVAVT06hIBh0amc22chgsiOclwQzjMKhILiOR2Rfh0xmcr3eUSGsXivCyAHFuLQWBkZgAJFicZLJXjUZZFVZldaVRBbHZ7A4Ek57c4Y4waHSCBVmbFHE56UlhN79CkxEaUGn0xnM+hs2lZOnc3mg0rlDbC7awsWHY6naUORBqSzuBWCNUI4yCZx3MlawY6756ukMpms+acADC1AmZoA0pbimCBRClHbRfsnW0XRc3Qg1MYLCR5QqDmYdMjGudg5FQ6Q0AQINgoJQIHIvgA3VAAa1IsAArgAjAC2FF4WrT-JtQpLNUEJCxQS0Fn9pZRGMMhjMsNROjOXl8gfVfSbnxIrfbWE7YAATo-UI+SOJkEQAGavickUeTtOs4iCsGYLpCiA+M0agBmY0JwXB8p6BijTNBYgiooYey7D6FhmI2HyUrAqAAMYYAQyAzpEXY9rE-ZDv+45TngVHYHOYGChBS7oaujzQhhW7GDuxaPG43qmAEph+BYhg6AR2rDqR5GUVqlBPi+b4ft+v6MYBLHASC6bWpx2YIFBJAwVi8HWAYPoYgYNyyd4qL+GiKjyc2JDqa+CSMKwHA8AIIF8hxWZQlstySgEaiohYtbQhiNaGJoeimOcUHXN07kauSnneY+vlJKk6TZHk7HGWFwqIqurSPLJ+L+EW9QWDCjTHrKzhqjo3XGB5175RGBrRsa7KchawVWuCtpmWYzQ6CYgj+mczhaOiIknLclaLZ1eyLSEOUhv1z4+fqUZGiaZq0uNhnziZVTGLNFYLUt9YBolni4pWy3WD6+hyQdV6UgNp2GjGczxomKblVNi4PXNz1Sitb3FrsNxxdoVidfoZb-ZehExLAT69hgJFgNQx0FXQflsFwfDQ5m03WBo6EijWarmFiu5aJo5ySSSSpYhYfVEUTJNkxThWsMVmQ5PkE1GTDXGzRorRdNoARNAlxZnI51z7keZj+j6wsE6LpPkxplDjFMMzzIs9PgaZcNPf6L1I2t9QuOWuirRJCK1moJvDmb4uWyDw1shyXI8vLt2VTN8Ou4jq0YkYuInF4x57puJJB-+IcWydkagyNprmjHN2hdNzvzUny0p8WtbGJFWxYk0ZZ7L1AP48Hj7E+bEvh+dsYJkmWSprHVew49teLcnyP1B0zdYaeeEIjFBihBqWCoBAcAKLlnygRV00ALQqBip9uKrgZqkle55yMx+K6ZTTpyKDzdf4soyitXrqDUFQ8p9DQjzreDsz8GaLm2HFWEvhLArTwjoOKxhdwBE0Mea41w6xAP2njBS-4lIUVYlmOO00YFuFStYVE+wawoMStzA4QQHi+E8DJQwed8qQMdlUWsOJdj7G8LQ7QtgRK1hIEJeE64HrUI4d3AhhM+5i0Lo+bhd1VCPAwfsGCHhPDQiak4PcsI9zyiAZuNeuNNSAwJiOEipNYDwBCifaBWhr6HCTjBLQBiED7mSvNGSsp0LIkEHI0IQA */
  createMachine(
    {
      context: {
        state: {
          email: '',
          password: '',
          errors: {
            email: '',
            password: '',
            serviceError: '',
          },
          data: undefined,
        },
      } as LoginStateContext,
      types: {} as {
        context: LoginStateContext;
        events: LoginStateEvent;
        actors:
          | {
              src: 'trackPlatformVisit';
              id: 'trackPlatformVisit';
              logic: typeof trackPlatformVisit;
            }
          | {
              src: 'didLoginOrRegister';
              id: 'didLoginOrRegister';
              logic: typeof didLoginOrRegister;
            }
          | {
              src: 'submitLogin';
              id: 'submitLogin';
              logic: typeof submitLogin;
            };
      },
      id: 'loginState',
      initial: 'init',
      states: {
        init: {
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
            },
            SUBMIT_FORM: [
              {
                guard: 'isFormInvalid',
                target: 'error',
              },
              {
                target: 'loading',
              },
            ],
            LOGIN_WITH_APPLE: {
              target: 'socialLogin',
            },
            LOGIN_WITH_GOOGLE: {
              target: 'socialLogin',
            },
            LOGIN_WITH_FACEBOOK: {
              target: 'socialLogin',
            },
          },
        },
        loading: {
          invoke: {
            src: 'submitLogin',
            id: 'submitLogin',
            input: ({ context, event }) => ({
              event,
              email: context.state.email,
              password: context.state.password,
            }),
            onDone: {
              target: 'success',
              actions: 'loginSuccess',
            },
            onError: [
              {
                actions: 'captureServiceError',
                target: 'serviceError',
              },
            ],
          },
        },
        socialLogin: {
          entry: ['clearForm'],
          invoke: {
            src: 'submitLogin',
            id: 'submitLogin',
            input: ({ context, event }) => ({
              event,
              email: context.state.email,
              password: context.state.password,
            }),
            onDone: {
              target: 'success',
              actions: 'loginSuccess',
            },
            onError: [
              {
                actions: 'captureServiceError',
                target: 'serviceError',
              },
            ],
          },
        },
        error: {
          entry: ['detectErrors'],
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
              target: 'init',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
              target: 'init',
            },
            LOGIN_WITH_APPLE: {
              target: 'socialLogin',
            },
            LOGIN_WITH_GOOGLE: {
              target: 'socialLogin',
            },
            LOGIN_WITH_FACEBOOK: {
              target: 'socialLogin',
            },
          },
        },
        serviceError: {
          on: {
            ENTER_EMAIL: {
              actions: 'captureEmail',
              target: 'init',
            },
            ENTER_PASSWORD: {
              actions: 'capturePassword',
              target: 'init',
            },
            SUBMIT_FORM: {
              target: 'loading',
            },
            LOGIN_WITH_APPLE: {
              target: 'socialLogin',
            },
            LOGIN_WITH_GOOGLE: {
              target: 'socialLogin',
            },
            LOGIN_WITH_FACEBOOK: {
              target: 'socialLogin',
            },
          },
        },
        success: {
          invoke: [
            {
              src: 'didLoginOrRegister',
              id: 'didLoginOrRegister',
              input: ({ context }) => ({
                userCredentials: context.state.data,
              }),
            },
            {
              src: 'trackPlatformVisit',
              id: 'trackPlatformVisit',
              input: ({ context }) => ({
                userCredentials: context.state.data,
              }),
            },
          ],
        },
      },
    },
    {
      guards: {
        isFormInvalid: ({ context }) => {
          return (
            !isEmail(context.state.email) || context.state.password.length < 6
          );
        },
      },
      actions: {
        captureEmail: assign(({ context, event }) => {
          if (event.type === 'ENTER_EMAIL') {
            return {
              state: {
                ...context.state,
                email: event.email,
                errors: {
                  ...context.state.errors,
                  email: '',
                },
              } as InitialLoginState,
            };
          }
          return context;
        }),
        capturePassword: assign(({ context, event }) => {
          if (event.type === 'ENTER_PASSWORD') {
            return {
              state: {
                ...context.state,
                password: event.password,
                errors: {
                  ...context.state.errors,
                  password: '',
                },
              } as InitialLoginState,
            };
          }
          return context;
        }),
        captureServiceError: assign(({ context, event }) => {
          const serviceError = mapFirebaseServiceError((event as any).error);

          return {
            state: {
              ...context.state,
              errors: {
                ...context.state.errors,
                serviceError,
              },
            } as InitialLoginState,
          };
        }),
        detectErrors: assign(({ context, event }) => {
          if (event.type === 'SUBMIT_FORM') {
            return {
              state: {
                ...context.state,
                errors: {
                  ...context.state.errors,
                  email: emailError(context.state.email),
                  password: passwordError(context.state.password),
                },
              } as InitialLoginState,
            };
          }
          return context;
        }),
        clearForm: assign(({ context, event }) => {
          if (
            event.type === 'LOGIN_WITH_GOOGLE' ||
            event.type === 'LOGIN_WITH_APPLE' ||
            event.type === 'LOGIN_WITH_FACEBOOK'
          ) {
            return {
              state: {
                ...context.state,
                errors: {
                  email: '',
                  password: '',
                },
              } as InitialLoginState,
            };
          }
          return context;
        }),
        loginSuccess: assign(({ context, event }) => {
          return {
            state: {
              ...context.state,
              data: (event as any).output,
            } as InitialLoginState,
          };
        }),
      },
      actors: {
        submitLogin: submitLogin,
        didLoginOrRegister: didLoginOrRegister,
        trackPlatformVisit: trackPlatformVisit,
      },
    },
  );

function emailError(email: string): EmailError {
  if (email.length === 0) {
    return 'Email is missing';
  }
  if (!isEmail(email)) {
    return 'Invalid email address';
  }
  return '';
}

function passwordError(password: string): PasswordError {
  if (password.length === 0) {
    return 'Password is missing';
  }
  if (password.length < 6) {
    return 'Password must be at least 6 characters';
  }
  return '';
}

/**
 * Maps a Firebase Auth error to a human-readable error message.
 *
 * @param error - The Firebase Auth error to map.
 * @returns The human-readable error message.
 */
export function mapFirebaseServiceError(error: AuthError) {
  switch (error.code) {
    case 'auth/wrong-password':
    case 'auth/invalid-email':
    case 'auth/user-not-found':
    case 'auth/user-disabled':
      return 'Invalid email or password.';
    case 'auth/email-already-in-use':
      return 'Email already in use.';
    case 'auth/too-many-requests':
      return 'Too many requests. Please try again later.';
    case 'auth/network-request-failed':
      return 'Network error. Please check your internet connection.';
    case 'auth/operation-not-allowed':
      return 'Operation not allowed. Please contact support.';
    case 'auth/popup-closed-by-user':
      return 'Popup closed by user.';
    default:
      return 'Something went wrong. Please contact support.';
  }
}

export default loginStateMachine;
