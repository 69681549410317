import * as React from 'react';

function LockOutlineIconComponent(props: any) {
  return (
    <svg
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 7.06998V5.33331C12 3.12665 10.2067 1.33331 8.00001 1.33331C5.79334 1.33331 4.00001 3.12665 4.00001 5.33331V7.06998C3.23334 7.28998 2.66667 7.99331 2.66667 8.83331V12.8333C2.66667 13.8433 3.49001 14.6666 4.50001 14.6666H11.5C12.51 14.6666 13.3333 13.8433 13.3333 12.8333V8.83331C13.3333 7.99665 12.7667 7.28998 12 7.06998ZM8.00001 2.33331C9.65334 2.33331 11 3.67998 11 5.33331V6.99998H5.00001V5.33331C5.00001 3.67998 6.34667 2.33331 8.00001 2.33331ZM12.3333 12.8333C12.3333 13.2933 11.96 13.6666 11.5 13.6666H4.50001C4.04001 13.6666 3.66667 13.2933 3.66667 12.8333V8.83331C3.66667 8.37331 4.04001 7.99998 4.50001 7.99998H11.5C11.96 7.99998 12.3333 8.37331 12.3333 8.83331V12.8333Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LockOutlineIconComponent;
