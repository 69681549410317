import { Machine, Project } from '@requests/projectsRequests';

export const isDefined = <T>(val: T | undefined | null): val is T =>
  val !== undefined && val !== null;

/* Not really a type guard but close enough */
export function outputIsInstanceOfDatabaseTable(
  interactionModule: any,
): boolean {
  if (
    (interactionModule?.type === 'codeValidatedInput' ||
      interactionModule?.type === 'codeNone' ||
      interactionModule?.type === 'codeFillTheGap' ||
      interactionModule?.type === 'codeOrdering') &&
    interactionModule?.output &&
    typeof interactionModule?.output === 'object'
  ) {
    return (
      'rows' in interactionModule.output && 'name' in interactionModule.output
    );
  }
  return false;
}

export const isEmail = (email: string) => {
  return email.match(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  );
};

export function isProject(object: any): object is Project {
  if (!object) {
    return false;
  }

  return (
    'id' in object &&
    'name' in object &&
    'files' in object &&
    'modifiedAt' in object &&
    'createdAt' in object
  );
}

export function isMachine(object: any): object is Machine {
  if (!object) {
    return false;
  }

  return (
    'id' in object &&
    'slug' in object &&
    'publicUrl' in object &&
    'createdAt' in object &&
    'lastAccessedAt' in object &&
    'websocketUrl' in object &&
    'displayName' in object &&
    'techStacks' in object
  );
}
