export const CancelIconComponent = (props: any) => (
  <svg
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.121 12L19.5 6.621C20.086 6.035 20.086 5.086 19.5 4.5C18.914 3.914 17.965 3.914 17.379 4.5L12 9.879L6.62105 4.5C6.03505 3.914 5.08605 3.914 4.50005 4.5C3.91405 5.086 3.91405 6.035 4.50005 6.621L9.87905 12L4.50005 17.379C3.91405 17.965 3.91405 18.914 4.50005 19.5C5.08605 20.086 6.03505 20.086 6.62105 19.5L12 14.121L17.379 19.5C17.965 20.086 18.914 20.086 19.5 19.5C20.086 18.914 20.086 17.965 19.5 17.379L14.121 12Z"
      fill="currentColor"
    />
  </svg>
);
