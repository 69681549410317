import toastHelper from 'react-hot-toast';
import { Toast } from 'react-hot-toast';
import { CancelIconComponent } from '../icons/cancelIcon';

export const ErrorToastComponent = ({
  toast,
  children,
}: {
  toast: Toast;
  children: React.ReactNode;
}) => (
  <div
    aria-hidden="true"
    onClick={() => toastHelper.dismiss(toast.id)}
    className={`${
      toast.visible ? 'animate-slide-in-from-top' : 'animate-leave'
    } text-product2-text-primary-light pointer-events-auto relative flex w-fit max-w-sm cursor-pointer rounded-xl border border-solid border-product2-accent-error bg-product2-background-secondary text-sm`}
  >
    <div className="flex items-center p-4 text-base">
      <CancelIconComponent className="h-6 w-6 flex-shrink-0 self-start text-product2-accent-error"></CancelIconComponent>
      <div className="ml-2">{children}</div>
    </div>
  </div>
);
